import React, { useEffect, useState, useRef, Fragment } from "react";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
import Dropzone from "react-dropzone";
import ComboBox from "react-responsive-combo-box";
import ComboWrapper from "./combowrapper";
import "react-responsive-combo-box/dist/index.css";
import {
  hiddenFields,
  nonInteractiveFields,
  globalState,
} from "../../utils/constants";
import {
  replaceAttrWithAlias,
  replaceAliasWithAttr,
  deleteFeatures,
  setLocalStorage,
  getLocalStorage,
  hideOnClickOutside,
} from "../../utils/esri-helpers";
import { projectManagerEditLayers, slugify } from "../../utils/constants.js";

import ReplaceButton from "../ui/replacebutton";
import ImportExportModal from "./importexportmodal";

let equal = require("deep-equal");

/* Contains the dropzone for the main upload functionality */

let lambdaUri = "http://localhost:8888";
const currentEnv = process.env.GATSBY_ENV;
if (currentEnv === "prod") {
  lambdaUri = "https://www.paverops.com";
}
if (currentEnv === "stage") {
  lambdaUri = "https://stage--paverops.netlify.app";
}

// Function just to stop the click from bubbling out and triggering the background exit
let interceptClick = (e) => {
  e.stopPropagation();
};

// Check if this is a drawing modal or not
let isDrawingSubmission = (dropzoneData) => {
  if (Array.isArray(dropzoneData)) {
    return true;
  } else {
    return false;
  }
};

// Gets us a fresh upload URL
let getS3Presigned = (timestamp, ext) => {
  return new Promise((resolve, reject) => {
    fetch(
      `${lambdaUri}/.netlify/functions/get-s3-presigned?timestamp=${timestamp}&ext=${ext}`
    )
      .then((response) => {
        return response.json();
        // Return the upload URL
      })
      .then((output) => {
        resolve(output);
      });
  });
};

// Takes a file, the callback arg has the binary conversion
let getBinaryFromFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      resolve(reader.result);
    });
    reader.addEventListener("error", (err) => {
      reject(err);
    });
    reader.readAsBinaryString(file);
  });
};

let handleUpload = async (timestamp, ext, file, paverReg) => {
  if (["json", "geojson", "zip"].indexOf(ext) !== -1) {
    // If we have a zip, get the zip type from the binary text
    let zipType = "";
    if (ext === "zip") {
      let fileText = await getBinaryFromFile(file);
      if (fileText.indexOf(".gdbtable") !== -1) {
        zipType = "gdb";
      } else {
        zipType = "shp";
      }
    }
    // Let's pre authorize with S3 so the client can upload the file directly
    let s3Output = await getS3Presigned(timestamp, ext);
    let uploadURL = s3Output.data.url;
    // Send the file over
    let uploadResp = await fetch(uploadURL, {
      method: "PUT",
      body: file,
      headers: {
        "Content-Type": file.type,
      },
    });
    if (uploadResp.status === 200) {
      // Add the file URL to the form data
      let urlWithoutQuery = uploadURL.split("?")[0];
      //console.log("FILE URL GOING IN", urlWithoutQuery)
      formData.append("fileURL", urlWithoutQuery);
      formData.append("username", paverReg.username);
      formData.append("org", paverReg.org);
      formData.append("timestamp", timestamp);
      formData.append("token", paverReg.token);
      formData.append("ext", ext);
      formData.append("zipType", zipType);
      // Now let's run our background request to do the actual upload to ArcGIS
      // TODO: At some point, update this to run directly on the user's computer since we're now using their own token
      let arcGISUpload = await fetch(
        `${lambdaUri}/.netlify/functions/upload-data-background`,
        {
          method: "post",
          body: formData,
        }
      );
      // We're going to get a 202 (unless something is wrong with Netlify)
      //console.log("bg data", arcGISUpload)
      if (arcGISUpload.status !== 202) {
        // It's a Netlify error so let's assume this resolves itself
        return { status: "error", error: "Upload error, please try again." };
      } else {
        //console.log("LET'S POLL SUCKAS")

        let pollResults = await pollForResults(timestamp, "upload");
        // console.log("pollResults", pollResults)

        if (pollResults.status === "ok") {
          // Uploaded successfully!
          // Send the good data back out
          return pollResults;
        } else {
          // If status is not ok, show the error
          return {
            status: "error",
            error:
              pollResults.error ||
              "Unable to parse features, please contact support.",
          };
        }
      }
    } else {
      return {
        status: "error",
        error: "S3 returned a non-200 response: " + uploadResp.status,
      };
    }
  } else {
    return {
      status: "error",
      error: "Error! This file extension is not supported for upload",
    };
  }
};

// Form to hold the dropzone file
let formData, form;

// We don't want to expose a usable token to the client, but we don't want to make the layer public either... and we don't want to use another serverless function or else we're going to burn through those requests
// Solution: We're going to use S3 to hold the result data, we will know exactly the URL to poll for it and it will be cheap
// The process has failed if we don't hear anything after 15 minutes
let pollForResults = (timestamp, prefix) => {
  //console.log("pollForResults", timestamp)
  return new Promise((resolve, reject) => {
    let failTimemout = setTimeout(() => {
      // We waited but heard nothing, we can give up now
      failTimemout = null;
      resolve({
        status: "error",
        error: "The server took too long to respond. Please try again later.",
      });
    }, 901000); // 15 minute timer

    let makeReq = () => {
      fetch(
        `https://paverops.s3.amazonaws.com/upload-status/stage${prefix}${timestamp}.json`
      )
        .then((response) => {
          // 403 indicates the file doesn't exist, we can keep waiting for it
          if (response.status === 200) {
            // We got the file, continue fetch logic
            return response.json();
          } else {
            // If we didn't get the file, wait 2 seconds and try again
            if (failTimemout !== null && globalState.dropModalOpen) {
              setTimeout(() => {
                makeReq();
              }, 2000);
            } else if (failTimemout !== null) {
              // If modal closed, end the timeout
              clearTimeout(failTimemout);
            }
            return null;
          }
        })
        .then((output) => {
          if (output) {
            //console.log("output", output)
            clearTimeout(failTimemout);
            resolve(output);
          }
        });
    };
    // Kick off the requests
    makeReq();
  });
};

// We need a slightly different poll for ArcGIS to check jobs and report back details
let checkJobStatus = (jobURL, setAppendCount, token, setCalc) => {
  return new Promise((resolve, reject) => {
    // We may not need this if it's just a job req
    let failTimemout = setTimeout(() => {
      // We waited but heard nothing, we can give up now
      failTimemout = null;
      resolve({
        status: "error",
        error: "The server took too long to respond. Please try again later.",
      });
    }, 901000); // 15 minute timer

    let makeReq = () => {
      fetch(`${jobURL}?f=json&token=${token}`)
        .then((response) => {
          // If the response, does not contain a status, then something is wrong -- error out
          if (response.status !== 200) {
            clearTimeout(failTimemout);
            resolve({
              status: "error",
              error:
                "ArcGIS returned an error for this upload. Any edits have been rolled back. Please try again.",
            });
            return null;
          } else {
            // We have a job in progress! Continue on
            return response.json();
          }
        })
        .then((output) => {
          // Update the count
          if (output.recordCount && !setCalc) {
            setAppendCount(output.recordCount);
          } else if (setCalc) {
            setCalc(true); // Let users know we're calculating
          }

          if (output.status !== "Completed") {
            // If not completed, keep waiting
            // Check to see if it failed outright
            if (output.status === "Failed") {
              // Return the error
              clearTimeout(failTimemout);
              let errorMsg = "Undefined data error";
              // Use ArcGIS's error if we have it
              if (output.error && output.error.description) {
                errorMsg = output.error.description;
              }
              //console.log("WE FAILED OUT", errorMsg)
              resolve({ status: "error", error: errorMsg });
            } else {
              // If we didn't get the file, wait 2 seconds and try again
              if (failTimemout !== null && globalState.dropModalOpen) {
                setTimeout(() => {
                  makeReq();
                }, 2000);
              } else if (failTimemout !== null) {
                // If modal closed, end the timeout
                clearTimeout(failTimemout);
              }
            }
          } else {
            // COMPLETE
            clearTimeout(failTimemout);
            resolve(output);
          }
        });
    };
    // Kick off the requests
    makeReq();
  });
};

const DropModal = ({
  setShowDropzone,
  dropzoneData,
  paverReg,
  editLayers,
  setUploadSelect,
  setShowErrorModal,
  modules,
}) => {
  let [fade, setFade] = useState("");
  let [uploadMsg, setUploadMsg] = useState(""); // Update the upload button
  let [replaceMsg, setReplaceMsg] = useState(""); // Cues the warning before a big replace
  let [mappables, setMappables] = useState([]);
  let mapOptionsRef = useRef(null); // Keeping updated value fresh even inside closure
  let [uploadData, setUploadData] = useState({});
  let [error, setError] = useState("");
  let [sizeReport, setSizeReport] = useState(null);
  let [featuresReport, setFeaturesReport] = useState(null);
  // Upload modal will map fields to these keys:
  let [attributes, setAttributes] = useState(null);
  // We can set what vars are mapped here
  let [onDeckData, setOnDeckData] = useState({});
  let [finalMapped, setFinalMapped] = useState({}); // Need to do this because we are not pure React and it's sad
  // Layer select
  let [uploadLayer, setUploadLayer] = useState(null);
  let [appendCount, setAppendCount] = useState(0);
  let [calc, setCalc] = useState(false);
  // Determine if we can show the import button or not
  let [isAdmin, setIsAdmin] = useState(false);
  let modalRef = useRef(null);
  let uploadRef = useRef(null);
  let mappableRef = useRef(null);

  // Handle import modal here too
  let [showImportModal, setShowImportModal] = useState(false);

  let { FeatureForm, Graphic, Tooltip } = modules;

  let accessibleLayers = Object.keys(editLayers);
  // If we want to limit what layers this org can create on, do that here
  if (paverReg.layers) {
    // But make sure we only show layers that actually exist (it will be different between stage and prod, for example)
    // Also, exclude "Complete" layers from creation since it's based on a filter
    let newLayerSet = [];
    for (let i = 0; i < Object.keys(editLayers).length; i++) {
      // Don't include alerts layers
      if (
        editLayers[Object.keys(editLayers)[i]].title
          .toLowerCase()
          .indexOf("alerts") > -1
      ) {
        continue;
      }
      // If the layer is in the list of layers the user can create on, add it
      if (
        (paverReg.layers.indexOf(Object.keys(editLayers)[i]) !== -1 ||
          paverReg.layers.length === 0) &&
        editLayers[Object.keys(editLayers)[i]].title
          .toLowerCase()
          .indexOf("complete") === -1 &&
        editLayers[Object.keys(editLayers)[i]].title
          .toLowerCase()
          .indexOf("future") === -1
      ) {
        // One last check -- if the user is a PM, make sure they can only create layers they have edit access to
        if (
          paverReg.role !== "Project Manager" ||
          (paverReg.role === "Project Manager" &&
            projectManagerEditLayers.indexOf(Object.keys(editLayers)[i]) !== -1)
        ) {
          // All conditions are met, push this in
          newLayerSet.push(editLayers[Object.keys(editLayers)[i]].title);
        }
      }
    }
    accessibleLayers = newLayerSet;
  }
  // Now, order them according to their designated place
  let sortingArr = [];
  modules.groupLayers.forEach((parent) => {
    let layerItems = parent.layers.items;
    for (let item in layerItems) {
      let thisItem = layerItems[item];
      sortingArr.push(thisItem.title);
    }
  });
  sortingArr.reverse();
  let layerSort = (a, b) => {
    return sortingArr.indexOf(a) - sortingArr.indexOf(b);
  };
  accessibleLayers = accessibleLayers.sort(layerSort);

  let updateForm = ({ canMap, clearFields }) => {
    //console.log("MAP THE ATTRS", editLayers[uploadLayer])
    if (editLayers[uploadLayer]) {
      // Destroy form if it exists already
      if (form) {
        form.destroy();
        // Replace form holder so we can create a new one
        let modalForm = document.getElementById("modal-form");
        if (modalForm) {
          modalForm.innerHTML = `<div id="modal-form-temp"></div>`;
        }
      }

      // Prep form for new data
      let newFormElements = [];
      let attrs = editLayers[uploadLayer].fields;
      // console.log("editLayers[uploadLayer]", editLayers[uploadLayer])
      let newFields = {};
      for (let attr in attrs) {
        let thisAttr = attrs[attr];
        // If it's not an attribute that's supposed to be hidden, show it
        if (
          hiddenFields.indexOf(thisAttr.name) === -1 &&
          nonInteractiveFields.indexOf(thisAttr.name) === -1 &&
          thisAttr.editable === true
        ) {
          // Adds to state
          newFields[thisAttr.name] = "";
          // Adds to form
          const fieldData = {
            type: "field",
            fieldName: thisAttr.name,
            label: thisAttr.alias,
          };
          // console.log("thisAttr.name", thisAttr.name)
          if (
            [
              "MorEnd",
              "PaveDate",
              "UploadDate",
              "AssessDate",
              "StartDate",
            ].indexOf(thisAttr.name) !== -1
          ) {
            fieldData.input = {
              type: "datetime-picker",
              includeTime: false,
            };
          }
          // Push it
          newFormElements.push(fieldData);
        }
      }

      // // Update the drop modal with the new fields
      // setAttributes(newFields)

      let savedAttrs = getLocalStorage("paverops_dropmodal", "attrs");
      //console.log("Getting localStorage", savedAttrs, clearFields)
      if (clearFields || !savedAttrs) {
        savedAttrs = {};
      }
      // Make sure that's the attr set we're dealing with now
      setAttributes(savedAttrs);

      // Paired down var to set the graphic
      let placeHold = {};
      Object.keys(savedAttrs).forEach((attr) => {
        placeHold[attr] = savedAttrs[attr].value;
      });

      // Grab the attributes from localStorage to set the initial values
      const graphicPlaceholder = new Graphic({
        attributes: placeHold,
      });
      //console.log("Here's the graphic", graphicPlaceholder, newFormElements, editLayers[uploadLayer])
      // console.log("newFormElements", newFormElements)

      // Update the FeatureForm with the new fields for this layer
      form = new FeatureForm({
        container: "modal-form-temp",
        groupDisplay: "sequential", // only display one group at a time
        layer: editLayers[uploadLayer],
        feature: graphicPlaceholder,
        formTemplate: {
          // Autocasts to new FormTemplate
          title: "Layer fields",
          elements: newFormElements,
        },
      });

      form.when(() => {
        // Listen for a value change so we can save the current state of the modal
        form.on("value-change", (e) => {
          //console.log('testing')
          setAttributes((attributes) => {
            // Find the type and add it to the attr
            const thisField = e.layer.fields.find((field) => {
              return field.name === e.fieldName;
            });

            // Set the val and the type
            let changedVal = {
              [e.fieldName]: {
                value: e.value,
                type: thisField.type,
              },
            };
            //console.log('thisField.type', e.fieldName)
            let newAttrObj = Object.assign({}, attributes, changedVal);
            setLocalStorage("paverops_dropmodal", "attrs", newAttrObj);
            //console.log("SETTING LOCALSTORAGE", e, attributes, changedVal, newAttrObj)

            // Return out the new value to set state
            return newAttrObj;
          });
        });
      });

      // If this is an upload, enable mapping UI (but only if we found mappable features)
      if (canMap) {
        // TODO: Wow this is a testament to nested hell, let's consider revamping this
        form.when(() => {
          let modalForm = document.getElementById("modal-form");
          if (modalForm) {
            modalForm.classList.add("shrink");
          }
          let formFields = document.querySelectorAll(
            "#modal-form-temp .esri-feature-form__label"
          );
          // Add mapping UI next to each label
          formFields.forEach((thisField) => {
            const newButton = document.createElement("div");
            newButton.className = "map-button";
            newButton.title = "Map to an attribute";
            newButton.innerHTML = `<img src="/globe.png" />`;
            thisField.append(newButton);

            // Find restrictive types
            let fieldType = "text";
            if (
              thisField.querySelector(
                ".esri-feature-form__date-input-container"
              )
            ) {
              fieldType = "date";
            } else if (thisField.querySelector('input[type="number"]')) {
              fieldType = "number";
            }
            // Add the event listener
            newButton.addEventListener("click", (e) => {
              e.stopPropagation();
              e.preventDefault();

              if (
                e.currentTarget.querySelector("#floating-mappables") &&
                !mappableRef.current.classList.contains("hide")
              ) {
                // If we already have the menu here, another click should remove instead
                if (mappableRef.current) {
                  mappableRef.current.classList.add("hide");
                }
                return;
              }

              // Move the mappables dropdown underneath
              if (mappableRef.current) {
                // Add the select to the button
                newButton.append(mappableRef.current);
                // Show the select
                mappableRef.current.classList.remove("hide");
                // Focus pops the menu
                mappableRef.current.querySelector("input").focus();

                // Prep to remove this menu if it receives a click outside
                const innerMenu = mappableRef.current.querySelector(
                  "#floating-mappables > div > div"
                );
                if (innerMenu) {
                  hideOnClickOutside(innerMenu, () => {
                    //console.log("CLICKED OUTSIDE")
                    if (mappableRef.current) {
                      mappableRef.current.classList.add("hide");
                    }
                  });
                }

                // Wipe away all events!
                mappableRef.current.innerHTML = mappableRef.current.innerHTML;
                // Re-instantiate events because we've screwed up React
                const options = mappableRef.current.querySelectorAll(".combo");
                options.forEach((item) => {
                  // Get the current item's type
                  let currentMappable = mapOptionsRef.current.find((option) => {
                    //console.log("OPENION", option)
                    return option.alias === item.textContent;
                  });
                  // Visible by default but disable options if the type doesn't match
                  item.style["display"] = "block";
                  // console.log(
                  //   "currentMappable",
                  //   currentMappable,
                  //   item.textContent,
                  //   mapOptionsRef.current,
                  //   options
                  // );
                  if (currentMappable) {
                    switch (fieldType) {
                      case "number":
                        if (
                          [
                            "esriFieldTypeSmallInteger",
                            "esriFieldTypeInteger",
                            "esriFieldTypeDouble",
                          ].indexOf(currentMappable.type) === -1
                        ) {
                          item.style["display"] = "none";
                        }
                        break;
                      case "date":
                        if (
                          ["esriFieldTypeDate"].indexOf(
                            currentMappable.type
                          ) === -1
                        ) {
                          item.style["display"] = "none";
                        }
                        break;
                    }
                  }
                  // Add event to each item
                  item.addEventListener("click", (e) => {
                    // Stop the other globe event from triggering
                    e.stopPropagation();
                    e.preventDefault();
                    // If we previously mapped, remove that label
                    let elem = thisField.querySelector(".map-text");
                    if (elem) {
                      elem.parentNode.removeChild(elem);
                    }
                    // Find the right attribute from newFormElements
                    let matchingLabel = thisField.innerHTML.match(/^(.*?)</);
                    if (matchingLabel.length >= 2) {
                      matchingLabel = matchingLabel[1];
                      // Compare to find the true attr
                      const itemMatch = newFormElements.find((item) => {
                        return item.label === matchingLabel;
                      });
                      // Finally, get the attr name
                      const matchingAttr = itemMatch.fieldName;
                      let syncText = e.currentTarget.innerText;
                      // console.log("syncText", syncText);
                      // See if we need to swap the true field back in
                      let foundItem = mapOptionsRef.current.find((item) => {
                        return item.alias === syncText;
                      });
                      // If we found an alias, make sure we sync the true field name so it matches up in the backend
                      if (foundItem) {
                        syncText = foundItem.field;
                      }
                      // Now make sure we know it's mapped
                      setOnDeckData({ key: matchingAttr, data: syncText });
                      // Hide the select
                      mappableRef.current.classList.add("hide");
                      // Show that we've mapped!
                      thisField.classList.add("mapped");
                      // Clear the value (if there was one)
                      let allInputs = thisField.querySelectorAll(
                        "input, select"
                      );
                      allInputs.forEach((item) => {
                        // Remove input interactivity
                        item.disabled = true;
                        // Clear the val
                        if (item.value) {
                          item.value = null;
                        }
                        if (item.selectedIndex) {
                          item.selectedIndex = 0;
                        }
                      });

                      // Add the map label
                      const mapText = document.createElement("div");
                      mapText.className = "map-text";
                      mapText.innerHTML = `<label><img src="/close-button.png" />Populate with <strong>${syncText}</strong></label>`;
                      thisField.append(mapText);

                      // Add click event to the X to undo this setting
                      let xButton = thisField.querySelector(".map-text img");
                      xButton.addEventListener("click", () => {
                        thisField.classList.remove("mapped");
                        allInputs.forEach((item) => {
                          // Restore input interactivity
                          item.disabled = false;
                          let elem = thisField.querySelector(".map-text");
                          if (elem) {
                            elem.parentNode.removeChild(elem);
                          }
                          // Unset this map
                          setOnDeckData({ key: matchingAttr, data: null });
                        });
                      });
                    }
                  });
                });
              }
            });
          });
        });
      }
    }
  };

  useEffect(() => {
    // If we have an incoming key, start the assignment process
    if (onDeckData["key"]) {
      // If data is a value, set the val on the attr
      let newMapData;
      if (onDeckData["data"] !== null) {
        let prepData = {
          [onDeckData["key"]]: onDeckData["data"],
        };
        newMapData = Object.assign({}, finalMapped, prepData);
      } else {
        // If the data is null, it's an unset
        newMapData = Object.assign({}, finalMapped);
        delete newMapData[onDeckData["key"]];
      }

      // ONLY re-render org data if it's unequal
      // This is super important -- without this check, mapcontext.js will get re-rendered as the user pans and zooms around the map
      if (!equal(finalMapped, newMapData)) {
        setFinalMapped(newMapData);
        //console.log("MAPPED DATA", newMapData)
      }
    }
  }, [onDeckData]);

  // On component init, change classes so it fades in
  useEffect(() => {
    // New formData object
    formData = new FormData();
    // Fade it in
    setFade("show");
    // Lock body scroll
    if (modalRef.current) {
      disableBodyScroll(modalRef.current);
    }
    // Set initial focus on the file upload
    if (uploadRef.current) {
      uploadRef.current.focus();
    }
    // Check data type -- if we received an array, then skip to entry status
    if (isDrawingSubmission(dropzoneData)) {
      // Append this file
      formData.append("drawing", JSON.stringify(dropzoneData));
      // Ask for field info
      setUploadMsg("data-entry");
    }

    // When this opens, set initial upload layer
    let savedLayer = getLocalStorage("paverops_dropmodal", "layer");
    if (!savedLayer || accessibleLayers.indexOf(savedLayer) === -1) {
      // No saved layer, so use the first layer
      savedLayer = accessibleLayers[0];
    }
    setUploadLayer(savedLayer);
    // Enable tooltip
    Tooltip.bindEvents();
    // Set global state
    globalState.dropModalOpen = true;

    // Check if this user is an admin
    checkIfAdmin();

    // Enable on cleanup
    return () => {
      clearAllBodyScrollLocks();
      globalState.dropModalOpen = false;
    };
  }, []);

  // When upload layer changes, change the attributes we're filling in
  useEffect(() => {
    // Allow feature mapping if it's not a drawing
    // And clear fields when we change layers
    updateForm({
      canMap: !isDrawingSubmission(dropzoneData),
      clearFields: !isDrawingSubmission(dropzoneData),
    });
  }, [uploadLayer]);

  let setUploadError = (err) => {
    setUploadMsg("");
    setReplaceMsg("");
    setMappables([]);
    mapOptionsRef.current = null;
    setAttributes({});
    setSizeReport(null);
    setFeaturesReport(null);
    setAppendCount(0);
    setCalc(false);
    setError(err || "Upload error, please try again.");
    // Reset form data
    formData = new FormData();
  };

  const showMappingSection = (uploadResult) => {
    if (uploadResult.result) {
      setMappables(uploadResult.result.attrs);
      mapOptionsRef.current = uploadResult.result.attrs;
      setUploadData(uploadResult.result.uploadData);
    }
    // Ask to reconcile fields
    setUploadMsg("data-entry");
    // Enable tooltip
    Tooltip.bindEvents();
    // Show the form
    updateForm({
      canMap: true,
      clearFields: !isDrawingSubmission(dropzoneData),
    });
  };

  const checkIfAdmin = async () => {
    // First we need to get their org id
    let resp = await fetch(
      `https://www.arcgis.com/sharing/rest/portals/self?f=json&token=${paverReg.token}`
    );
    resp = await resp.json();
    // console.log("resp", resp)
    let adminStatus = resp.user.role;
    if (adminStatus === "org_admin" || adminStatus === "org_publisher") {
      setIsAdmin(true);
    }
  };

  // Function to allow feature uploads
  let uploadFeatures = async (replace) => {
    // We need another fresh timestamp for tracking the new file
    let newTimestamp = new Date().getTime();
    // Grab updated attributes from the form
    let updated = form.getValues();
    // Merge them into the attributes, so we have something to assign
    let clonedAttrs = Object.assign({}, attributes);
    Object.keys(updated).forEach((update) => {
      if (clonedAttrs[update]) {
        clonedAttrs[update].value = updated[update];
      }
    });
    // Append attributes
    formData.append("attrmap", JSON.stringify(clonedAttrs));
    // Append what should be mapped
    formData.append("mapvars", JSON.stringify(finalMapped));
    // Add uploadData so we can fast-track the request
    formData.append("uploadData", JSON.stringify(uploadData));
    // Include layer URL
    formData.append("layerURL", editLayers[uploadLayer].url);
    // All the rest
    formData.append("username", paverReg.username);
    formData.append("org", paverReg.org);
    formData.append("memberid", paverReg.id);
    formData.append("pavauth", paverReg.pavauth);
    formData.append("timestamp", newTimestamp);
    formData.append("token", paverReg.token);

    // Append the uploaded file
    fetch(`${lambdaUri}/.netlify/functions/publish-data`, {
      method: "post",
      body: formData,
    })
      .then((data) => {
        // console.log("DATA FROM PUB ENDPOINT", data)
        if (data.error || data.errorMessage) {
          // Kick it back to the upload step
          // The attributes are saved, will be pre-filled if they want to upload the same data again
          // Fill with the error text from the server
          setUploadError(data.error || data.errorMessage);
          return null;
        } else if (data.status === 200) {
          // We need to poll again for the real response from s3, first return as JSON
          // console.log("CHECK STATUS!")
          return data.json();
        }
      })
      .then((output) => {
        // Only normal uploads will proceed here
        if (output) {
          checkJobStatus(
            output.result.statusUrl,
            setAppendCount,
            paverReg.token
          )
            .then((uploadPolling) => {
              //console.log("uploadPolling complete", uploadPolling)
              // Handle err
              if (uploadPolling.status === "error") {
                setUploadError(uploadPolling.error);
                return null;
              }

              // Add create time to the formData
              // We are using this to find what we appended!
              let date = new Date(parseInt(uploadPolling.submissionTime));
              date = date.toISOString().substr(0, 19).replace("T", " ");
              formData.append("createTime", date);
              formData.append("timestamp", newTimestamp);
              formData.append("token", paverReg.token);

              // Now we need to use the calculate API to update all those new features with our standard fields
              // We are using the app token as a work around, which is why this needs to be a background process (the original status URL can't be queried using the user's token)
              fetch(
                `${lambdaUri}/.netlify/functions/post-process-data-background`,
                {
                  method: "post",
                  body: formData,
                }
              )
                .then((output) => {
                  // if (!output.result.statusUrl){
                  //   setUploadError("An error occurred adding organization attributes. Please try again later.")
                  //   return null
                  // }
                  //console.log("POST PROCESS OUTPUT", output)
                  //checkJobStatus(output.result.statusUrl, setAppendCount, paverReg.token, setCalc).then((calculatePolling) => {
                  pollForResults(newTimestamp, "calc").then(
                    (calculatePolling) => {
                      // Handle err
                      //console.log("calculatePolling", calculatePolling)
                      if (calculatePolling.status === "error") {
                        setUploadError(calculatePolling.error);
                        return null;
                      }

                      // Do we need to request a layer refresh here to see the features? TRY IT
                      editLayers[uploadLayer].refresh();

                      // Try getting the uploaded features 10 times... if we can't get them, we'll give up
                      let tries = 0;
                      let triesMax = 10;
                      // If we got 0 back from the calculate step, we know this will fail -- fail immediately
                      if (calculatePolling.result.recordCount === 0) {
                        console.error("Calculate returned zero!");
                        tries = triesMax;
                      }
                      let getFeatures = () => {
                        // If we've tried 10 times, give up
                        if (tries >= triesMax) {
                          setUploadError(
                            "Features could not be added to your organization's layer. Please try again or contact support."
                          );
                          return null;
                        }
                        // If we want to select all new features after upload, we can make a separate request to the layer to get all features whose "created_date" matches the lastUpdatedTime coming from the completion data
                        //console.log("DATE COMPARE", date)
                        editLayers[uploadLayer]
                          .queryObjectIds({
                            where: `last_edited_date >= TIMESTAMP '${date}' AND created_user = '${paverReg.username}'`,
                          })
                          .then((results) => {
                            // If we have 0 results here, it may mean we haven't indexed yet, so try again in a sec
                            // NOTE: We likely don't need this retry logic now that we're waiting for calc to finish properly, but it's not hurting anything to keep it
                            if (results.length === 0) {
                              setTimeout(() => {
                                tries++;
                                //console.log("RETRYING GET FEATURES "+tries+" TIMES")
                                getFeatures();
                              }, 2000);
                              return null;
                            }
                            //console.log("AFTER UPLOADER RESULTS", results)
                            let selectDetails = {
                              layer: uploadLayer,
                              ids: results,
                            };
                            // Go to the extent when we're done
                            setUploadSelect(selectDetails);
                            // Set to upload layer so we can update that layer in the org data
                            setShowDropzone(uploadLayer);
                          });
                      };
                      // Kick it off
                      getFeatures();
                    }
                  );
                })
                .catch((err) => {
                  setUploadError(err);
                });
            })
            .catch((err) => {
              setUploadError(err);
            });
        }
      });
  };

  // Function to allow feature uploads
  let uploadDrawing = () => {
    // Grab updated attributes from the form
    let updated = form.getValues();
    // Merge them into the attributes, so we have something to assign
    let clonedAttrs = Object.assign({}, attributes);
    Object.keys(updated).forEach((update) => {
      if (clonedAttrs[update]) {
        clonedAttrs[update].value = updated[update];
      }
    });
    // Append attributes
    formData.append("attrmap", JSON.stringify(clonedAttrs));
    // Include layer URL
    formData.append("layerURL", editLayers[uploadLayer].url);
    // All the rest
    formData.append("username", paverReg.username);
    formData.append("org", paverReg.org);
    formData.append("memberid", paverReg.id);
    formData.append("pavauth", paverReg.pavauth);
    formData.append("token", paverReg.token);
    // Send the file over
    fetch(`${lambdaUri}/.netlify/functions/upload-drawing`, {
      method: "post",
      body: formData,
    }).then((data) => {
      if (data.error || data.errorMessage) {
        // Kick it back to the upload step
        // The attributes are saved, will be pre-filled if they want to upload the same data again
        // Fill with the error text from the server
        setUploadError(data.error || data.errorMessage);
        return null;
      } else {
        setUploadMsg("success");
        // Wait a sec to hide for ~ ergonomics ~
        setTimeout(() => {
          // Set to upload layer so we can update that layer in the org data
          setShowDropzone(uploadLayer);
        }, 1000);
        return null;
      }
    });
  };

  // Because we now include the
  let displayMappables = [];
  if (mappables) {
    displayMappables = mappables.map((item) => {
      //return replaceAttrWithAlias(item.field, editLayers[uploadLayer])
      return item.alias || item.field;
    });
  }
  displayMappables = displayMappables.sort();

  return (
    <>
      <div
        className={"modal-wrapper " + fade}
        // Comment this out because uploads can take a long time and it's a huge bummer to accidentally cancel
        //onMouseDown={() => {setShowDropzone(false)}}
      >
        <div
          className="drop-modal floating"
          ref={modalRef}
          onMouseDown={interceptClick}
        >
          {isDrawingSubmission(dropzoneData) && (
            <div
              style={{ position: "absolute", top: "10px", left: "10px" }}
              dangerouslySetInnerHTML={{
                __html: `<div class="question" style="float: right;" rel="tooltip" data-helptext="Use this form to create new features in layers your organization has access to. Attribute values from the previous Create Feature operation are stored and available for reuse, be modified, or removed. Click the “Clear Fields” button to clear the stored values."></div>`,
              }}
            />
          )}
          {!isDrawingSubmission(dropzoneData) && uploadMsg === "data-entry" && (
            <div
              style={{ position: "absolute", top: "10px", left: "10px" }}
              dangerouslySetInnerHTML={{
                __html: `<div class="question" style="float: right;" rel="tooltip" data-helptext="When uploading data, only layers your organization has access to are available in the Upload Layer drop-down list. When mapping attributes, it is important to verify the field types match. If they do not, those attribute values cannot not be transfered, unless the PaverOps field is text, which accepts any value by converting it to text. If an attribute value is added manually, that value will be added to all features in the uploaded data. Zip files must contain only one feature class or shapefile layer."></div>`,
              }}
            />
          )}
          {["data-entry", "final-upload", "success"].indexOf(uploadMsg) ===
          -1 ? (
            <Dropzone
              onDrop={(acceptedFiles, fileRejections) => {
                // Bail out if we're already in progress
                if (uploadMsg === "data-upload") {
                  return false;
                }
                // Start off by clearing the error if there was one
                setError("");
                // Handle the dropped/selected files
                if (fileRejections.length > 0) {
                  setError(
                    "Error! Please only upload a single file, not multiple"
                  );
                } else if (acceptedFiles.length > 0) {
                  // Do extension checking here
                  let ext = acceptedFiles[0].path
                    .split(".")
                    .pop()
                    .toLowerCase();
                  setSizeReport(acceptedFiles[0].size / 1000000);
                  // Create the unique timestamp for this file
                  let newTimestamp = new Date().getTime();
                  // Ask for field info
                  setUploadMsg("data-upload");
                  handleUpload(
                    newTimestamp,
                    ext,
                    acceptedFiles[0],
                    paverReg
                  ).then((uploadResult) => {
                    if (uploadResult.status === "error") {
                      setUploadError(uploadResult.error);
                    } else {
                      // If we got attrs back, show them
                      showMappingSection(uploadResult);
                      // Kick off one last polling process to see when the new layer is ready
                      // TODO: At some point, just analyze the file instead of creating a new layer, no need for that
                      pollForResults(newTimestamp, "publish")
                        .then((pubResult) => {
                          setFeaturesReport(pubResult.result);
                          // Enable tooltip
                          Tooltip.bindEvents();
                        })
                        .catch((err) => {
                          setUploadError(err);
                        });
                    }
                  });
                }
              }}
              maxFiles={1}
              multiple={false}
              autoProcessQueue={false}
              noKeyboard
            >
              {({ getRootProps, getInputProps }) => (
                <div
                  className="drop-inner"
                  {...getRootProps({
                    onClick: (e) => {
                      // Prevent the whole box from opening the dialog
                      if (e.target !== document.querySelector("#file-upload")) {
                        e.preventDefault();
                        e.stopPropagation();
                      }
                    },
                  })}
                >
                  <div className="drop-options">
                    <p>
                      Upload from your local files or import from your
                      organization to upload new data
                    </p>
                    <p>
                      Supported file types:
                      <br />
                      {/*
                    NOTE: GeoJSON DOES work, however Esri does not seem to support fieldMapping on the /append endpoint for geojson... who knows why
                    TODO: Maybe we can work on a conversion from GeoJSON to SHP under the hood so this will work like normal again
                    UNTODO: No ^ never do this, absolutely not
                    GeoJSON (as .json or .geojson)<br />
                    */}
                      File Geodatabase (as .zip)
                      <br />
                      Shapefile (as .zip)
                    </p>
                    {sizeReport && (
                      <p>
                        Upload size is{" "}
                        {sizeReport >= 0.01
                          ? sizeReport.toFixed(2)
                          : "less than 0.01"}{" "}
                        megabytes, please wait while PaverOps processes this
                        file.
                        {sizeReport > 5 && " This may take several minutes."}
                      </p>
                    )}
                    {error && (
                      <div
                        className="error-text"
                        dangerouslySetInnerHTML={{ __html: error }}
                      />
                    )}
                    <button
                      className="primary"
                      id="file-upload"
                      ref={uploadRef}
                    >
                      <input {...getInputProps()} />
                      {uploadMsg === "data-upload" ? (
                        <Fragment>
                          Uploading your file
                          <span className="loading">
                            <img src="/loading.png" alt="Loading icon" />
                          </span>
                        </Fragment>
                      ) : (
                        <span>Select from files</span>
                      )}
                    </button>
                    {uploadMsg !== "data-upload" && isAdmin && (
                      <button
                        className="primary"
                        onClick={() => {
                          setShowImportModal(true);
                        }}
                      >
                        Import from organization layers
                      </button>
                    )}
                    <button
                      className="secondary"
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowDropzone(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </Dropzone>
          ) : (
            // The data-entry stage
            <div className="data-entry">
              <div className="drop-options">
                {isDrawingSubmission(dropzoneData) ? (
                  <Fragment>
                    <p>
                      Drawing complete. Please select the layer to add the new
                      feature to and populate its attributes.
                    </p>
                    <button
                      className="critical small"
                      onClick={() => {
                        updateForm({ canMap: false, clearFields: true });
                      }}
                    >
                      Clear fields
                    </button>
                    <div className="field-box">
                      <label htmlFor={"layer"}>
                        <span>Layer</span>
                      </label>
                      {uploadLayer && (
                        <ComboWrapper
                          // Limit the layers to only the editable ones for this user
                          options={accessibleLayers}
                          editable={false}
                          onSelect={(option) => {
                            setUploadLayer(option);
                            setLocalStorage(
                              "paverops_dropmodal",
                              "layer",
                              option
                            );
                            // Clear the saved attrs if we've changed layers
                            setLocalStorage("paverops_dropmodal", "attrs", {});
                          }}
                          defaultValue={uploadLayer}
                          className="combo"
                        />
                      )}
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>
                    <p>
                      Data recognized! Select the target layer, then map your
                      attributes to PaverOps fields or enter new values for
                      these uploaded features
                    </p>
                    {/* Ian has asked we NOT do autofill on uploads, so we are removing the clear button as well
                    <button className="critical small" onClick={() => {
                      updateForm({canMap: true, clearFields: true})
                    }}>Clear fields</button>
                    */}
                    <p>
                      Attributes found in your data:&nbsp;
                      {displayMappables.length > 0 ? (
                        <span>
                          {displayMappables.map((item, index) => {
                            return (
                              <strong key={item}>
                                {item}
                                {index < mappables.length - 1 ? ", " : ""}
                              </strong>
                            );
                          })}
                        </span>
                      ) : (
                        <strong>None</strong>
                      )}
                    </p>
                    <div className="field-box">
                      <label htmlFor={"layer"}>
                        <span>Upload Layer</span>
                      </label>
                      {uploadLayer && (
                        <ComboWrapper
                          // TODO: Limit the layers to only the editable ones for this user
                          options={accessibleLayers}
                          editable={false}
                          onSelect={(option) => {
                            setUploadLayer(option);
                            setLocalStorage(
                              "paverops_dropmodal",
                              "layer",
                              option
                            );
                            // Clear the saved attrs if we've changed layers
                            setLocalStorage("paverops_dropmodal", "attrs", {});
                          }}
                          defaultValue={uploadLayer}
                          className="combo"
                        />
                      )}
                    </div>
                    <div
                      id="floating-mappables"
                      ref={mappableRef}
                      className="hide"
                    >
                      <ComboWrapper
                        options={displayMappables}
                        editable={false}
                        className="combo"
                      />
                    </div>
                  </Fragment>
                )}
                <div className="field-list" id="modal-form">
                  <div id="modal-form-temp" />
                </div>
                {!isDrawingSubmission(dropzoneData) && (
                  <Fragment>
                    {!featuresReport && appendCount === 0 ? (
                      <div className="features-report">
                        We are analyzing your upload to get a final feature
                        count. You can submit an upload before this process is
                        complete.
                        <span className="loading">
                          <img src="/loading.png" alt="Loading icon" />
                        </span>
                      </div>
                    ) : (
                      <div className="features-report">
                        {appendCount === 0 ? (
                          <span>
                            This will add {featuresReport.toLocaleString()}{" "}
                            features to your selected layer.
                          </span>
                        ) : (
                          <span>
                            Added {appendCount.toLocaleString()} features to
                            layer...
                          </span>
                        )}
                      </div>
                    )}
                  </Fragment>
                )}
                <button
                  className="primary"
                  id="submit-upload"
                  onClick={(e) => {
                    e.stopPropagation();
                    // Prevent button mashing
                    if (
                      uploadMsg !== "data-entry" ||
                      ["final-replace", "success"].indexOf(replaceMsg) !== -1
                    ) {
                      return false;
                    }
                    setUploadMsg("final-upload");
                    if (!isDrawingSubmission(dropzoneData)) {
                      uploadFeatures();
                    } else {
                      // Special function just for drawing upload
                      uploadDrawing();
                    }
                  }}
                >
                  {uploadMsg === "data-entry" && (
                    <Fragment>
                      {isDrawingSubmission(dropzoneData) ? (
                        <span>Add new feature</span>
                      ) : (
                        <span>Submit upload</span>
                      )}
                    </Fragment>
                  )}
                  {uploadMsg === "final-upload" && (
                    <Fragment>
                      Adding features to layer
                      <span className="loading">
                        <img src="/loading.png" alt="Loading icon" />
                      </span>
                    </Fragment>
                  )}
                  {uploadMsg === "success" && <span>Feature added!</span>}
                </button>
                {!isDrawingSubmission(dropzoneData) && (
                  <div className="replace-wrapper">
                    <div
                      style={{
                        position: "absolute",
                        top: "12px",
                        left: "-30px",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: `<div class="question" style="float: right;" rel="tooltip" data-helptext="Selecting this option will programmatically delete all your organization’s features in the selected PaverOps layer and add the data in the uploaded file in their place."></div>`,
                      }}
                    />
                    <ReplaceButton
                      editLayers={editLayers}
                      uploadLayer={uploadLayer}
                      uploadFunc={uploadFeatures}
                      replaceMsg={replaceMsg}
                      setReplaceMsg={setReplaceMsg}
                      paverReg={paverReg}
                      uploadMsg={uploadMsg}
                      esriRequest={modules.esriRequest}
                    />
                  </div>
                )}
                <button
                  className="secondary"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowDropzone(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      {showImportModal && (
        <ImportExportModal
          setShowImportModal={setShowImportModal}
          showMappingSection={showMappingSection}
          paverReg={paverReg}
          setFeaturesReport={setFeaturesReport}
          type="import"
        />
      )}
    </>
  );
};

export default DropModal;
